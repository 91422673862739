import * as React from 'react';
import { Container, ElementList } from '@nimles/react-web-components';
import styled from '@emotion/styled';
import { LayoutModel } from '@nimles/models';
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import queryString from 'query-string';
import { useQuery } from '@apollo/react-hooks';
import { layoutQuery } from '../Layout';
import MessengerCustomerChat from 'react-messenger-customer-chat';

const StyledFooter = styled.div`
  z-index: 1;
  background-color: ${({ theme }) => theme.footer.background};
  color: ${({ theme }) => theme.footer.color};
  min-height: 20vh;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Copyright = styled.div`
  font-size: 12px;
  padding: 10px 0;
`;

interface LinkItem {
  to: string;
  name: string;
}

interface Props {
  items?: LinkItem[];
  layout: LayoutModel;
  location: Location;
}

export const FooterLayout: React.FC<Props> = ({ layout, location }) => {
  const { language } = React.useContext(I18nextContext);

  const { draft }: any = location
    ? queryString.parse(location.search)
    : { draft: false };

  const { data } = useQuery<{ layout: LayoutModel }>(layoutQuery, {
    variables: { tenantId: process.env.GATSBY_TENANT, id: layout?.id },
    skip: !draft,
  });

  if (data?.layout) {
    layout = data.layout || layout;
  }

  return (
    <StyledFooter>
      {layout ? (
        <ElementList
          elements={layout.elements}
          tenantId={process.env.GATSBY_TENANT}
          location={location}
          root="Header"
          culture={language}
        />
      ) : null}
      <Container>
        <Copyright>© 2021 {process.env.GATSBY_NAME}</Copyright>
      </Container>
      {process.env.GATSBY_FACEBOOK_PAGE_ID &&
      process.env.GATSBY_FACEBOOK_PAGE_ID ? (
        <div>
          <MessengerCustomerChat
            pageId={process.env.GATSBY_FACEBOOK_PAGE_ID}
            appId={process.env.GATSBY_FACEBOOK_APP_ID}
            greetingDialogDisplay="show"
          />
        </div>
      ) : null}
    </StyledFooter>
  );
};
