import React, { FC, useContext } from 'react';
import { Header, ElementList } from '@nimles/react-web-components';
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import { LayoutModel } from '@nimles/models';
import { useQuery } from '@apollo/react-hooks';
import { layoutQuery } from '../Layout';
import queryString from 'query-string';
import { Props } from './types';

export const HeaderLayout: FC<Props> = ({ alwaysOpen, location, layout }) => {
  const { language } = useContext(I18nextContext);
  const { draft }: any = location
    ? queryString.parse(location.search)
    : { draft: false };

  const { data } = useQuery<{ layout: LayoutModel }>(layoutQuery, {
    variables: { tenantId: process.env.GATSBY_TENANT, id: layout?.id },
    skip: !draft,
  });

  if (data?.layout) {
    layout = data.layout || layout;
  }

  return (
    <Header alwaysOpen={alwaysOpen} neverHide={true}>
      {layout ? (
        <ElementList
          elements={layout.elements}
          tenantId={process.env.GATSBY_TENANT}
          location={location}
          root="Header"
          culture={language}
        />
      ) : null}
    </Header>
  );
};
