import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { LicensePlateField } from '@nimles/react-web-forms';
import { Row, Column } from '@nimles/react-web-components';
import { LOAD_PUBLIC_VEHICLE } from '@nimles/react-redux/dist/propertyManagement/actionTypes';
import styled from '@emotion/styled';
import { VehicleModel } from '@nimles/models';
import { RootState } from '../redux/types';

const LicenseplateColumn = styled(Column)`
  font-size: 14px;
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    font-size: 16px;
  }
`;

interface Props {
  onComplete?: (vehicle: VehicleModel) => void;
}

export const LicensePlateSearch = ({ onComplete }: Props) => {
  const dispatch = useDispatch();
  const vehicle = useSelector<RootState, VehicleModel>(
    ({ publicVehicles }) => publicVehicles.values[0]
  );

  const handleSubmit = async ({ licensePlate }) => {
    // const vehicle = await dispatch(
    //   loadPublicVehicles({ provider: 'Biluppgifter', licensePlate })
    // );
    const vehicle: VehicleModel = { licensePlate };
    dispatch({
      type: LOAD_PUBLIC_VEHICLE,
      data: vehicle,
    });
    if (onComplete) {
      onComplete(vehicle);
    }
  };

  return vehicle ? (
    <div>{vehicle.licensePlate}</div>
  ) : (
    <Formik
      initialValues={{ licensePlate: '' }}
      enableReinitialize
      onSubmit={(values, actions) => handleSubmit(values)}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        setFieldValue,
      }) => {
        return (
          <Form onSubmit={handleSubmit} noValidate>
            <Row>
              <LicenseplateColumn flex>
                <LicensePlateField
                  name="licensePlate"
                  value={values.licensePlate}
                />
              </LicenseplateColumn>
            </Row>
          </Form>
        );
      }}
    />
  );
};

export default LicensePlateSearch;
