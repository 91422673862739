import React from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const animate = keyframes`
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
		transform: rotate(45deg) translate(10px, 10px);
	}
	100% {
		opacity: 0;
	}
`;

const StyledScrollDown = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);

  span {
    width: 20px;
    height: 20px;
    display: block;
    border-right: 2px solid ${({ theme }) => theme.colors.primary.color};
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary.color};
    transform: rotate(45deg);
    margin: -10px;
    animation: ${animate} 2s infinite;
  }

  span:nth-of-type(2) {
    animation-delay: -0.2s;
  }

  span:nth-of-type(3) {
    animation-delay: -0.4s;
  }
`;

export const ScrollDown = () => (
  <StyledScrollDown>
    <span></span>
    <span></span>
    <span></span>
  </StyledScrollDown>
);
