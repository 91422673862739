import React, { FC } from 'react';
import { Section } from '@nimles/react-web-components';
import styled from '@emotion/styled';
import { ScrollDown } from '../ScrollDown';

export const Content = styled(Section)`
  position: relative;
  padding: 70px 0 0;
  flex: 1 0 auto;
`;

export const StyledSection = styled(Section)`
  padding: 0;
  text-align: center;
  color: ${({ theme }) => theme.banner?.primary?.onColor};
  position: relative;
  p {
    margin-bottom: 30px;
  }
  h1 {
    margin-bottom: 10px;
  }
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    text-align: left;
  }
`;

export const Filler = styled.div`
  position: relative;
  flex: 0 0 100%;
  background-color: ${({ theme }) => theme.banner?.primary?.color};
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    flex: 1;
  }
`;

export const ImageBackground = styled(Section)`
  display: flex;
  flex: 1;
  order: 1;
  margin-left: -100%;
  z-index: -1;
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    margin-left: -30%;
  }
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    margin-left: -300px;
  }
`;

export const BannerSection: FC = ({ children, ...props }) => {
  return (
    <StyledSection {...props}>
      <Content>{children}</Content>
      {props['minHeight'] === '100vh' ? <ScrollDown /> : null}
    </StyledSection>
  );
};
