import React, { useState } from 'react';
import {
  Card,
  CardAction,
  CardActions,
  CardBody,
  CardImage,
  Column,
  Row,
} from '@nimles/react-web-components';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import styled from '@emotion/styled';
import { getImageUrl } from '../../../utils';
import {
  CartModel,
  PricelistModel,
  ProductModel,
  VariantModel,
} from '@nimles/models';
import { PricelistPublicModel } from '@nimles/models/dist/public/ecommerce';
import { useDispatch, useSelector } from 'react-redux';
import { State, updateCart } from '@nimles/react-redux';
import { RootState } from '../../../redux/types';
import { Price } from '../../price/Price';
import { useCart } from '../../../hooks/cartHook';
import { useCurrency } from '../../../hooks/currencyHook';
import { ExclVat } from '../Vat';
import YoutubeEmbed from '../../video/YoutubeEmbed';

const ProductLink = styled(Link)`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 800ms;

  & section {
    border: 1px solid #efefef;
    overflow: hidden;
    transition: all 400ms;
  }

  &:hover section {
    border-color: ${({ theme }) => theme.colors.primary.color};
    transition: all 200ms;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 40%), 0 1px 1px 0 rgb(0 0 0 / 28%),
      0 2px 1px -1px rgb(0 0 0 / 24%);
  }
`;

const Description = styled.div`
  flex: 1 0 auto;
  position: relative;
  font-size: 14px;
  line-height: 18px;
  max-height: 85px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.surface.onColor};

  &::after {
    content: '';
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      transparent 100%
    );
    display: block;
    position: absolute;
    height: 10px;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 10px;
  font-family: 'Lato', sans-serif;
  flex: 1 0 auto;
`;

interface Props {
  product: ProductModel;
  variant: VariantModel;
  pricelists: PricelistPublicModel[];
}

export const ProductCard = ({ product, variant, pricelists }: Props) => {
  const isGross = useSelector<RootState>(({ grossPrice }) => grossPrice);

  const { t } = useTranslation();
  const { addProduct } = useCart();
  const { currency } = useCurrency();
  const [isFocused, setFocused] = useState(false);

  const pricelist = pricelists.find((p) => p.currency === currency);

  const handleAddProduct = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    addProduct(product.id, variant.id);
  };

  const netPrice =
    pricelist?.prices?.find(
      (p) => p.productId === product.id && p.variantId === variant.id
    )?.netPrice ??
    variant?.netPrice ??
    product?.netPrice;

  const grossPrice =
    pricelist?.prices?.find(
      (p) => p.productId === product.id && p.variantId === variant.id
    )?.grossPrice ??
    variant?.grossPrice ??
    product?.grossPrice;

  const discountNetPrice = pricelist?.prices?.find(
    (p) => p.productId === product.id && p.variantId === variant.id
  )?.discountNetPrice;

  const discountGrossPrice = pricelist?.prices?.find(
    (p) => p.productId === product.id && p.variantId === variant.id
  )?.discountGrossPrice;

  const name = variant.name || product.name;
  const summary = variant.summary || product.summary;
  const imageIds = variant.imageIds || product.imageIds;

  return (
    <ProductLink
      to={`/${product.uniqueName}-${variant.id}`}
      onMouseOver={() => setFocused(true)}
      onMouseOut={() => setFocused(false)}
    >
      <Card flex>
        <CardImage
          src={getImageUrl(imageIds?.length ? imageIds[0] : null, 480)}
          alt={name}
          ratio={1.5}
          fit="contain"
        />
        <CardBody>
          <Name>{name}</Name>
          <Description dangerouslySetInnerHTML={{ __html: summary }} />
          <Row justify="center">
            <Column alignItems="center">
              <Price
                value={isGross === true ? grossPrice : netPrice}
                discountValue={isGross ? discountGrossPrice : discountNetPrice}
                currency={currency}
              />
              {isGross ? <ExclVat>{t('prop.exclVat')}</ExclVat> : null}
            </Column>
          </Row>
        </CardBody>
        <CardActions justify="center">
          <CardAction
            aria-label={t('action.add') + ' ' + name}
            primary={isFocused}
            raised={isFocused}
            onClick={handleAddProduct}
          >
            {t('action.addToCart')}
          </CardAction>
        </CardActions>
      </Card>
    </ProductLink>
  );
};
